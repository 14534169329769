var problem = {};
$(document).ready(function () {
    let $page = $("#problem-page"),
        $table = {};

    problem = {
        dtTable: {},

        init: function () {
            $table = $page.find("#problem-datatable");
            this.dtTable = $table.DataTable({
                processing: true,
                serverSide: true,
                searching: false,
                lengthChange: false,
                ajax: {
                    url: "/ajax/problem",
                    type: "get",
                    data: function (d) {
                        d.mode = "datatable";
                        d.keyword = $("#filter-keyword").val();
                    },
                },
                columns: [
                    { data: "id", name: "id" },
                    { data: "name", name: "name" },
                    { data: "created_at", name: "created_at" },
                    { data: "updated_at", name: "updated_at" },
                    { data: "action", name: "action" },
                ],
                columnDefs: [
                    { targets: "no-sort", orderable: false },
                    { targets: "text-center", className: "text-center" },
                    { targets: "text-center", className: "text-center" },
                    { targets: "text-center", className: "text-center" },
                    { targets: "text-end", className: "text-end" },
                ],
            });

            $table.on("click", ".btn-delete", function (e) {
                e.preventDefault();
                rowId = $(this).attr("data-id");
                helper.deleteConfirm(
                    "Are you sure to delete this data?",
                    problem.deleteData,
                    rowId
                );
            });

            $("#filter-keyword").on("keydown", function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    problem.dtTable.draw();
                }
            });

            $("#btn-filter").on("click", function (e) {
                problem.dtTable.draw();
            });
        },

        deleteData: function (rowId) {
            $.ajax({
                url: "/problem/" + rowId,
                type: "DELETE",
                dataType: "json",
                success: function (response) {
                    problem.dtTable.draw();
                    helper.setAlert(response.message, "success");
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },
    };

    if ($page.length) {
        problem.init();
    }
});
