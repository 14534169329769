const { initial } = require("lodash");

window.andon_inspection_show = {};
$(document).ready(function () {
    let $page = $("#inspection_show-page");
    let url = window.location.origin + "ajax/inspections";
    let inspection_id = window.location.pathname.split("/")[2];

    andon_inspection_show = {
        init: function () {
            dt_table.init();
        },
        refresh: function ($data = null) {
            if ($data !== null && $data !== false) {
                let data = $.parseJSON($data);

                dt_table.reloadTable();
                let ng_ratio_text = `${data.ng_ratio} %`;
                $("#ng_ratio_" + inspection_id).text(ng_ratio_text);

                let cycle_total_text = `${data.cycle_total} %`;
                $("#cycle_total_" + inspection_id).text(cycle_total_text);

                let ng_total_text = `${data.ng_total} %`;
                $("#ng_total_" + inspection_id).text(ng_total_text);

                let ok_total_text = `${data.ok_total} %`;
                $("#ok_total_" + inspection_id).text(ok_total_text);
            }
        },
        getNgRatio: function () {
            $.ajax({
                url: url,
                data: function (d) {
                    d.mode = "ng_ratio";
                    d.inspection_id = inspection_id;
                },
                success: function (res) {
                    console.log("success", res);
                },
                error: function (res) {
                    console.log("error : ", res);
                },
            });
        },
    };

    dt_table = {
        dt_instance: null,
        init: function () {
            // this.dt_instance = $page.find("#inspection_show-datatable");
            $.ajax({
                url: "/ajax/inspections/show",
                type: "POST",
                data: {
                    inspection_id: inspection_id,
                },
                success: function (data) {
                    console.log("init", data);
                    // console.log(dt_table);
                    let formatted = dt_table.formatData(data);

                    if (formatted.data.length != 0) {
                        dt_table.generateTableHeaders(formatted.maxSensors);
                        dt_table.initializeDataTable(formatted.maxSensors);
                    } else {
                        $("#inspection_show-datatable tbody").append(
                            '<tr><td colspan="100%" class="text-center">No Data Available</td></tr>'
                        );
                    }
                },
                error: function () {
                    initializeDataTable(0); // Initialize with zero sensors if there's an error
                },
            });
        },
        formatTimestamp(timestamp) {
            let date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
            // Create a formatter for Indonesian time (WIB, UTC+7)
            let options = {
                timeZone: "Asia/Jakarta",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            };
            let formatter = new Intl.DateTimeFormat("id-ID", options);
            return formatter.format(date).replace(",", "");
        },
        formatData(data) {
            let maxSensors = 0;
            // console.log("formatData", data);
            let formattedData = data.map((item) => {
                // console.log("item cycle : ", item.cycle);
                let formattedItem = {
                    cycle: item.cycle,
                    last_server_time: dt_table.formatTimestamp(
                        item.last_server_time
                    ),
                    result: item.result === 1 ? "OK" : "NG",
                };

                item.counting_inspection_details.forEach((detail) => {
                    // console.log(`sensor ${detail.sensor} detail : ${detail}`);
                    formattedItem[`sensor${detail.sensor}_timestamp`] =
                        dt_table.formatTimestamp(detail.server_time);
                    formattedItem[`sensor${detail.sensor}_status`] =
                        detail.status === 1 ? "OK" : "NG";
                    maxSensors = Math.max(maxSensors, parseInt(detail.sensor));
                });

                if (item.counting_inspection_details.length == 0) return;

                // Ensure all sensor fields exist and set to empty string if missing

                for (let i = 1; i <= maxSensors; i++) {
                    if (!formattedItem[`sensor${i}_timestamp`]) {
                        formattedItem[`sensor${i}_timestamp`] = "";
                    }
                    if (!formattedItem[`sensor${i}_status`]) {
                        formattedItem[`sensor${i}_status`] = "";
                    }
                }

                return formattedItem;
            });

            return { data: formattedData, maxSensors: maxSensors };
        },
        generateTableHeaders(maxSensors) {
            let mainHeaderRow = $("#inspectionTableHeader tr:first");
            let subHeaderRow = $("#sensorSubHeaders");

            // Clear any existing sensor headers
            mainHeaderRow.find("th:gt(2)").remove();
            subHeaderRow.empty();

            // Add new sensor headers
            for (let i = 1; i <= maxSensors; i++) {
                mainHeaderRow.append(
                    `<th class="text-center" colspan="2">Sensor ${i}</th>`
                );
                subHeaderRow.append(
                    `<th class="text-center">Timestamp</th><th class="text-center">Status</th>`
                );
            }
        },
        generateColumns(maxSensors) {
            let columns = [
                { data: "cycle", className: "text-center" },
                { data: "last_server_time", className: "text-center" },
                {
                    data: "result",
                    className: "text-center",
                    render: function (data, type, row) {
                        if (data == "NG") {
                            return (
                                '<span class="text-danger font-weight-bold">' +
                                data +
                                "</span>"
                            );
                        } else {
                            return '<span class="text-success">OK</span>';
                        }
                    },
                },
            ];

            for (let i = 1; i <= maxSensors; i++) {
                columns.push({
                    data: `sensor${i}_timestamp`,
                    className: "text-center",
                });
                columns.push({
                    data: `sensor${i}_status`,
                    className: `text-center`,
                    render: function (data, type, row) {
                        if (data == "NG") {
                            return (
                                '<span class="text-danger font-weight-bold">' +
                                data +
                                "</span>"
                            );
                        } else {
                            return '<span class="text-success">OK</span>';
                        }
                    },
                });
            }

            // console.log("Columns: ", columns);
            return columns;
        },
        initializeDataTable: function (maxSensors) {
            // console.log("max sensors", maxSensors);
            this.dt_instance = $("#inspection_show-datatable").DataTable({
                ajax: {
                    url: "/ajax/inspections/show",
                    type: "POST",
                    data: {
                        inspection_id: inspection_id,
                    },
                    dataSrc: function (json) {
                        let formatted = dt_table.formatData(json);
                        // console.log(
                        //     " initializeDataTable formatted: ",
                        //     formatted
                        // );
                        dt_table.generateTableHeaders(formatted.maxSensors);
                        return formatted.data;
                    },
                },
                paging: false,
                fixedHeader: true,
                columns: dt_table.generateColumns(maxSensors),
                destroy: true,
                rowCallback: function (row, data, index) {},
            });
        },
        reloadTable: function (params) {
            $.ajax({
                url: "/ajax/inspections/show", // replace with your data source URL
                type: "POST",
                data: {
                    inspection_id: inspection_id,
                },
                success: function (data) {
                    console.log("reload table data: ", data);
                    let formatted = dt_table.formatData(data);

                    if (dt_table.dt_instance) {
                        dt_table.dt_instance.destroy();
                    }

                    if (formatted.data.length != 0) {
                        dt_table.generateTableHeaders(formatted.maxSensors);
                        dt_table.initializeDataTable(formatted.maxSensors);
                    }
                    dt_table.dt_instance.draw();

                    // dt_table.dt_instance
                    //     .clear()
                    //     .rows.add(formatted.data)
                    //     .draw();
                },
                error: function () {
                    if (dt_table.dt_instance) {
                        dt_table.dt_instance.clear().draw(); // Clear the table if there's an error
                    }
                },
            });
        },
    };

    if ($page.length) {
        andon_inspection_show.init();
    }
});
