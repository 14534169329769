window.andon_inspection_history = {};
$(document).ready(function () {
    let $page = $("#inspection_history-page");
    let url = window.location.origin + "ajax/inspections";
    let inspection_id = window.location.pathname.split("/")[2];

    let start_date = null;
    let end_date = null;

    andon_inspection_history = {
        init: function () {
            this.initDatePickers();
            // console.log(start_date, end_date);
            dt_history_table.init();
        },
        initDatePickers: function () {
            let startDateInput = $("#startDate");
            let endDateInput = $("#endDate");

            start_date = this.formatDate(this.getDates().firstDayOfMonth);
            end_date = this.formatDate(this.getDates().lastDayOfMonth);

            startDateInput.val(start_date);
            endDateInput.val(end_date);

            startDateInput
                .datepicker({
                    format: "yyyy/mm/dd",
                    autoclose: true,
                })
                .on("changeDate", function (e) {
                    endDateInput.datepicker("setStartDate", e.date);

                    start_date = andon_inspection_history.formatDate(e.date);

                    if (end_date != null) {
                        // dt_history_table.ajax.reload().draw();
                        dt_history_table.reloadTable();
                    }
                });

            endDateInput
                .datepicker({
                    format: "yyyy/mm/dd",
                    autoclose: true,
                })
                .on("changeDate", function (e) {
                    startDateInput.datepicker("setEndDate", e.date);

                    end_date = andon_inspection_history.formatDate(e.date);

                    if (start_date != null) {
                        // dt_history_table.ajax.reload().draw();
                        dt_history_table.reloadTable();
                    }
                });
        },
        formatDate: function (date) {
            var year = date.getFullYear();
            var month = String(date.getMonth() + 1).padStart(2, "0");
            var day = String(date.getDate()).padStart(2, "0");
            return `${year}/${month}/${day}`;
        },
        getDates: function () {
            let currentDate = new Date();
            let firstDayOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );
            let lastDayOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );

            return { firstDayOfMonth, lastDayOfMonth };
        },
        refresh: function ($data = null) {
            if ($data !== null && $data !== false) {
                let data = $.parseJSON($data);

                dt_history_table.reloadTable();
            }
        },
    };
    dt_history_table = {
        dt_instance: null,
        init: function () {
            console.log(start_date, end_date);
            $.ajax({
                url: "/ajax/inspections/history",
                type: "POST",
                data: {
                    inspection_id: inspection_id,
                    start_date: start_date,
                    end_date: end_date,
                },
                success: function (res) {
                    let formatted = dt_history_table.formatData(
                        res.data,
                        res.dates,
                        res.footer_data
                    );

                    // console.log(res.data);
                    // console.log(Object.values(res.data));

                    if (Object.keys(res.data).length > 0) {
                        dt_history_table.generateTableHeaders(formatted.dates);
                        dt_history_table.initializeDataTable(
                            formatted.dates,
                            formatted.data
                        );
                        dt_history_table.generateTableFooter(
                            formatted.footer_data
                        );
                    } else {
                        $("#inspection_history-datatable tbody").append(
                            '<tr><td colspan="100%" class="text-center">No Data Available</td></tr>'
                        );
                    }
                },
                error: function () {
                    initializeDataTable(0);
                },
            });
        },
        initializeDataTable: function (dates, data) {
            this.dt_instance = $("#inspection_history-datatable").DataTable({
                ajax: {
                    url: "/ajax/inspections/history",
                    type: "POST",
                    data: {
                        inspection_id: inspection_id,
                        start_date: start_date,
                        end_date: end_date,
                    },
                    dataSrc: function (json) {
                        let formatted = dt_history_table.formatData(
                            json.data,
                            json.dates,
                            json.footer_data
                        );

                        // dt_history_table.generateTableHeaders(formatted.dates);
                        // dt_history_table.dt_instance.clear();

                        return formatted.data;
                    },
                },
                dom: "tip",
                searching: false,
                columns: dt_history_table.generateColumns(dates),
                destroy: true,
                rowCallback: function (row, data, index) {},
            });
        },
        reloadTable: function () {
            $.ajax({
                url: "/ajax/inspections/history",
                type: "POST",
                data: {
                    inspection_id: inspection_id,
                    start_date: start_date,
                    end_date: end_date,
                },
                success: function (res) {
                    let formatted = dt_history_table.formatData(
                        res.data,
                        res.dates,
                        res.footer_data
                    );
                    console.log("reload data", formatted.footer_data);

                    if (
                        formatted.data.length > 0 &&
                        dt_history_table.dt_instance
                    ) {
                        console.log("if 1");
                        dt_history_table.dt_instance.destroy();
                        dt_history_table.generateTableHeaders(formatted.dates);

                        $("#inspection_history-datatable tbody").empty();
                        dt_history_table.initializeDataTable(
                            formatted.dates,
                            formatted.data
                        );
                        dt_history_table.generateTableFooter(
                            formatted.footer_data
                        );
                    } else if (
                        formatted.data.length > 0 &&
                        !dt_history_table.dt_instance
                    ) {
                        console.log("if 2");
                        dt_history_table.generateTableHeaders(formatted.dates);
                        dt_history_table.initializeDataTable(
                            formatted.dates,
                            formatted.data
                        );
                        dt_history_table.generateTableFooter(
                            formatted.footer_data
                        );
                    } else if (formatted.data.length == 0) {
                        console.log("if 3");
                        if (dt_history_table.dt_instance) {
                            dt_history_table.generateTableHeaders(
                                formatted.dates
                            );
                            dt_history_table.generateTableFooter(
                                formatted.footer_data
                            );
                            dt_history_table.dt_instance.clear().draw();
                        }
                    }
                },
                error: function () {
                    if (dt_history_table.dt_instance) {
                        dt_history_table.dt_instance.clear().draw();
                    }
                },
            });
        },
        formatData(data, dates, footer_data) {
            let formattedData = [];

            // console.log("format data", dates);

            for (let sensor in data) {
                let row = { no: sensor, sensor: `Sensor ${sensor}` };
                dates.forEach((date) => {
                    if (data[sensor][date]) {
                        row[`${date}_actual`] = data[sensor][date].actual;
                        row[`${date}_ok_total`] = data[sensor][date].ok_total;
                        row[`${date}_ng_total`] = data[sensor][date].ng_total;
                        row[`${date}_ng_ratio`] = data[sensor][date].ng_ratio;
                    } else {
                        row[`${date}_actual`] = "";
                        row[`${date}_ok_total`] = "";
                        row[`${date}_ng_total`] = "";
                        row[`${date}_ng_ratio`] = "";
                    }
                });
                formattedData.push(row);
            }

            return {
                data: formattedData,
                dates: dates,
                footer_data: footer_data,
            };
        },
        generateColumns(dates) {
            if (!dates || dates.length == 0) {
                return [];
            }

            let columns = [
                { data: "no", className: "text-center" },
                { data: "sensor", className: "text-center" },
            ];

            dates.forEach((date) => {
                columns.push({
                    data: `${date}_actual`,
                    className: "text-center",
                    title: "Actual",
                });
                columns.push({
                    data: `${date}_ok_total`,
                    className: "text-center",
                    title: "OK Total",
                });
                columns.push({
                    data: `${date}_ng_total`,
                    className: "text-center",
                    title: "NG Total",
                });
                columns.push({
                    data: `${date}_ng_ratio`,
                    className: "text-center",
                    title: "NG Ratio",
                });
            });

            return columns;
        },
        generateTableHeaders(dates) {
            let mainHeaderRow = $("#inspectionHistoryTableHeader tr:first");
            let subHeaderRow = $("#dateSubHeaders");
            let labelHeaderRow = $("#labelSubHeaders");

            mainHeaderRow.find("th:gt(1)").remove();
            subHeaderRow.empty();
            labelHeaderRow.empty();

            if (dates.length == 0) {
                return;
            }

            mainHeaderRow.append(
                `<th class="text-center" colspan="${
                    dates.length * 4
                }">Tanggal</th>`
            );

            for (let i = 0; i < dates.length; i++) {
                subHeaderRow.append(
                    `<th class="text-center" colspan="4">
                    <a href="/inspections/${inspection_id}/show">
                    ${dates[i]}
                    </a>
                    </th>`
                );
                labelHeaderRow.append(
                    `<th class="text-center">Actual</th><th class="text-center">Total OK</th><th class="text-center">Total NG</th><th class="text-center">NG Ratio (%)</th>`
                );
            }
        },
        generateTableFooter(footer_data) {
            let mainFooterRow = $("#inspectionHistoryTableFooter tr:first");
            // console.log(mainFooterRow);
            mainFooterRow.find("th:gt(0)").remove();

            if (footer_data.length == 0) {
                return;
            }

            // console.log("sini pooo");
            for (let date in footer_data) {
                let actual = footer_data[date].total_actual;
                let ng_ratio = footer_data[date].ng_ratio;
                let total_ng = footer_data[date].total_ng;
                let total_ok = footer_data[date].total_ok;

                mainFooterRow.append(
                    `<th class="text-center">${actual}</th><th class="text-center">${total_ok}</th><th class="text-center">${total_ng}</th><th class="text-center">${ng_ratio}%</th>`
                );
            }
        },
    };

    if ($page.length) {
        andon_inspection_history.init();
    }
});
