import * as echarts from "echarts";

let oee_workstation = {};
$(document).ready(function () {
    let $page = $("#oee-workstation-page");
    const page = window.location.pathname.split("/")[1];

    let url = window.location.origin + "/ajax/oee-workstation";

    oee_workstation = {
        init: function () {
            this.getData();
        },
        getData() {
            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                data: {
                    mode: "chart",
                },
                success: function (response) {
                    if (response.length > 0) {
                        oee_workstation.renderChart(response);
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },
        renderChart: function (data) {
            console.log(data);

            data.forEach((record) => {
                let canvas = document.getElementById(
                    "chart_container_" + record.id
                );

                let option = {
                    tooltip: {
                        trigger: "item",
                    },
                    series: [
                        {
                            name: `chart-${record.id}`,
                            type: "pie",
                            radius: "50%",
                            data: [
                                { value: 1048, name: "Search Engine" },
                                { value: 735, name: "Direct" },
                                { value: 580, name: "Email" },
                                { value: 484, name: "Union Ads" },
                                { value: 300, name: "Video Ads" },
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };

                let chart = echarts.init(canvas, "light");

                chart.setOption(option);
            });
        },
        update: function () {
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: {
                    mode: "runtime_job_status",
                    register_log: register_log_id,
                },
                success: function (response) {
                    if (response.length > 0) {
                        let data = [];

                        response.forEach((status_record) => {
                            data.push({
                                value: status_record.data,
                                name: status_record.label,
                            });
                        });

                        andon_chart.status_record_chart.setOption({
                            series: [
                                {
                                    data: data,
                                },
                            ],
                        });
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },
    };
    if ($page.length) {
        oee_workstation.init();
    }
});
